/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */



/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import '@ionic/angular/css/palettes/dark.class.css';

body {
    font-family: 'Montserrat', serif;
}

h1 {
    font-family: 'Merriweather', serif;
    font-weight: 700;
}

h2,
h3,
h4,
h5 {
    font-family: 'Montserrat', serif;
    font-weight: 700;
}

a {
    color: #000;
}

p,
.text-normal {
    font-weight: 400;
    font-family: 'Montserrat', serif;
}

.italic-text {
    font-style: italic;
    font-family: 'Merriweather', serif;
}

ion-header {
    box-shadow: none !important;
    border-bottom: 1px solid #e9e9e9;
    background-color: #fff !important;

    .logo {
        height: 20px;
        margin: 0px 20px;
    }

    @media (max-width: 575px) {
        .logo {
            height: 30px;
            margin: 0px 20px;
        }
    }
}

ion-footer {
    box-shadow: none !important;
    border-top: 1px solid #e9e9e9;
}

.container {
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;

    @media (max-width: 1199px) {
        max-width: 960px;
    }

    @media (max-width: 991px) {
        max-width: 720px;
    }

    @media (max-width: 767px) {
        max-width: 540px;
    }

    @media (max-width: 575px) {
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
}

ion-thumbnail {
    border: 1px solid #000 !important;
}

.userprofilelist {

    ion-list,
    ion-item {
        h1 {
            font-weight: bold;
            font-size: 1em;
        }

        h2 {
            font-size: 0.8em;
        }

        p {
            margin: 2px 0px;
        }

        .useravatar {
            width: 50px;
            height: 50px;
        }

        @media (min-width: 768px) {
            .useravatar {
                width: 80px;
                height: 80px;
            }
        }
    }
}

ion-button {
    --border-radius: 0px;
}

.duascols {
    .colleft {
        border-left: 1px solid #e9e9e9;
        padding-left: 20px;
        padding-top: 20px;
        min-height: 100vh;
        height: 100%;
    }

    .colright {
        padding-top: 20px;
    }

    ion-grid,
    ion-col,
    ion-row {
        margin: 0px;
        padding: 0px;
    }
}


.modalcomments {

    --height: 100%;
    --border-radius: 0px;
    padding-top: 80px;

}

@media (max-width: 768px) {
    .modalcomments {
        --height: 100%;
        --width: 95%;

    }


}

.cardinterview {
    box-shadow: none;
    border: 1px solid #000;
    border-radius: 0px;
    margin: 10px;
    transition: box-shadow 0.3s ease-in-out;

    h3 {
        font-size: 1.2em;
    }

    a {
        color: #000;
        text-decoration: none;
    }

    span {
        color: #3f3f3f !important;
    }

    ion-chip {
        border-radius: 0px;
    }
}

.cardinterview:hover {
    box-shadow: 3px 3px 0 0 #000, 6px 6px 0 0 #000;
}


.custom-alert-delete .alert-wrapper {
    border: 3px solid red;
}

.custom-alert-delete .cancel-button-class {
    color: var(--ion-color-medium);
}

.custom-alert-delete .delete-button-class {
    color: var(--ion-color-danger);
}

.custom-alert-delete::part(backdrop) {
    background-color: rgba(255, 0, 0, 0.4);
}

.fullscreen-modal {
    --height: 100%;
    --min-height: 100%;
    --max-height: 100%;
    --width: 100%;
    --min-width: 100%;
    --max-width: 100%;
    --border-radius: 0;
}